import { useEffect, useContext, useState } from "react";
import { serverAddress, AuthContext, appId } from "../App";
import { useParams, useNavigate } from "react-router-dom";
import MenuBar from "../components/MenuBar";
import { Link } from "react-router-dom";
import back from "../assets/back.svg";
import "./Detail.css";

export default function Detail() {
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (state.isLogin===false) {
      navigate("/");
    }
  })
  const [detail, setDetail] = useState({});
  const [nftImg, setNftImg] = useState();
  const params = useParams();

  const nameList = ["《自然爱人·茶艺师》","《回忆藏家·摄影师》","《镜头哲人·导演》","《理想绘手·画家》","《未知旅人·旅行家》","《调味职人·烘培师》","《天际行者·宇航员》"];

  const getSubStr = (str) => {
    var subStr1 = str.substr(0, 7);
    var subStr2 = str.substr(str.length - 19, 19);
    var subStr = subStr1 + "..." + subStr2;
    return subStr;
  };

  useEffect(async () => {
    if (state.accessToken) {
      let headers = new Headers();
      headers.append("appId", appId);
      headers.append("accessToken", state.accessToken);
      try {
        const response = await fetch(serverAddress + "/nft/detail/" + params.id, {
          method: "GET",
          headers: headers,
        });
        const json = await response.json();
        if (json.returnCode === "20000") {
          setDetail({
            updateTime: json.data.updateTime,
            name: (json.data.variety==="7")?(nameList[parseInt(json.data.variety)-1]+"（隐藏款）"):(nameList[parseInt(json.data.variety)-1])
          });
          setNftImg("https://elements.cocafe.co/nayuki/image/card/" + json.data.variety + ".png")
        } else {
          console.log(json.message);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  },[state.accessToken])
  return (
    <div className="detail">
      <MenuBar />
      <div className="title-bar mt-14">
        <Link to="/mynft" className="back"><img src={back} /></Link>
        <div className="page-title">{detail.name}</div>
      </div>
      <div className="contract">合约<span className="address">地址：</span>cfx:achvvn0y...46e138fnysw</div>
      <div className="nft-container">
        <div className="nft-border">
          <img className="nft" src={nftImg} />
        </div>
      </div>
      <div className="nft-information">
        <div className="information-title">数字藏品信息</div>
        <div className="information-content">
          <div className="information-item">
            <div className="label">发行方</div>
            <div className="value">奈雪的茶</div>
          </div>
          <div className="information-item">
            <div className="label">创建时间</div>
            <div className="value">{detail.updateTime}</div>
          </div>
          <div className="information-item">
            <div className="label">创作作者</div>
            <div className="value">NAYUKI</div>
          </div>
          <div className="information-item">
            <div className="label">藏品名称</div>
            <div className="value">{detail.updateTime}</div>
          </div>
        </div>
      </div>
      <div className="notice">
      上述NFT创意作品的知识产权由奈雪的茶拥有，NFT的所有权人或者合法拥有者不得将上述NFT创意作品用于任何商业途径以及任何非法途径。本网站仅代为展示和提取NFT创意作品，严格禁止自然人、法人或非法人组织利用NFT创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。
    </div>
    <div className="poster-generate">
      <button onClick={()=>{navigate("/poster/" + params.id)}}>海报生成</button></div>
    </div>
  )
}